import { SearchResult } from "@elastic/search-ui";
import { Fragment } from "react";

const ResultField = ({
  result,
  field,
  title,
  useRaw = false,
  hideEmpty = false
}: {
  result: SearchResult;
  field: string;
  title: string;
  useRaw?: boolean;
  hideEmpty?: boolean;
}) => (
  hideEmpty && !result[field]?.snippet && !result[field]?.raw
    ? null
    : <Fragment>
        <dt>{title}</dt>
        <dd>
          <span dangerouslySetInnerHTML={{
            __html: useRaw ? result[field]?.raw : result[field]?.snippet || result[field]?.raw
          }}></span>
        </dd>
      </Fragment>
);

const VendorResultView = ({
  result,
  onClickLink
}: {
  result: SearchResult;
  onClickLink: () => void;
}) => (
  <li className="sui-result">
    <div className="sui-result__header">
      <span className="sui-result__title" dangerouslySetInnerHTML={{
        __html: result.vendorname1?.snippet || result.vendorname1?.raw
      }}></span>
    </div>
    <div className="sui-result__body">
      <div className="sui-result__details">
        <dl className="vendor-result">
          <ResultField result={result} field="vat" title="Vat" />
          <ResultField result={result} field="search_terms" title="Search terms" hideEmpty={true} />
          <ResultField result={result} field="category" title="Category" />
          <ResultField result={result} field="vendor_classification" title="Vendor classification" hideEmpty={true} />
          <ResultField result={result} field="homepage" title="Homepage" hideEmpty={true} useRaw={true} />
          <ResultField result={result} field="finland" title="Finland" hideEmpty={true} />
          <ResultField result={result} field="sweden" title="Sweden" hideEmpty={true} />
          <ResultField result={result} field="denmark" title="Denmark" hideEmpty={true} />
          <ResultField result={result} field="estonia" title="Estonia" hideEmpty={true} />
          <ResultField result={result} field="latvia" title="Latvia" hideEmpty={true} />
          <ResultField result={result} field="lithuania" title="Lithuania" hideEmpty={true} />
          <ResultField result={result} field="poland" title="Poland" hideEmpty={true} />
        </dl>
      </div>
    </div>
    {/* <pre>{JSON.stringify(result, null, 2)}</pre> */}
  </li>
);

export default VendorResultView;
