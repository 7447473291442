import React, { useEffect,useState } from 'react';
import {
  MsalAuthenticationTemplate,
  useMsal,
  useIsAuthenticated,
} from '@azure/msal-react';
import { InteractionStatus,InteractionType } from "@azure/msal-browser";
import { loginRequest } from './authConfig';
import AppSearchAPIConnector from "@elastic/search-ui-app-search-connector";
import {
  ErrorBoundary,
  Facet,
  SearchProvider,
  SearchBox,
  Results,
  PagingInfo,
  ResultsPerPage,
  Paging,
  Sorting,
  WithSearch
} from "@elastic/react-search-ui";
import { Layout } from "@elastic/react-search-ui-views";
import "@elastic/react-search-ui-views/lib/styles/styles.css";
import {
  buildAutocompleteQueryConfig,
  buildFacetConfigFromConfig,
  buildSearchOptionsFromConfig,
  buildSortOptionsFromConfig,
  getConfig,
  getFacetFields
} from "./config/config-helper";
import VendorResultView from './components/VendorResultView';

export default function App() {
  const { hostIdentifier, endpointBase, engineName } = getConfig();
  const isAuthenticated = useIsAuthenticated();
  const { instance, inProgress } = useMsal();

  const [token, setToken] = useState("0");
  const [isLoading, setLoading] = useState(true);
  const [loggedIn, setLoggedIn] = useState(false);
  const [callbackAdded, setCallbackAdded] = useState(false);

  useEffect(() => {
    if (callbackAdded) return;
    setCallbackAdded(true);

    console.log("Adding MSAL event callback");
    instance.addEventCallback((event: { eventType: string; payload: any; }) => {
      if (event.eventType === "msal:loginSuccess") {
        if (event.payload) {
          console.debug("MSAL: setting active account on login");
          const result = event.payload;
          const account = result.account;
          instance.setActiveAccount(account);
          setLoggedIn(true);
        }
      }
    });
  }, [callbackAdded, instance]);

  useEffect(() => {
    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      instance.loginRedirect(loginRequest)
    };
    if (isAuthenticated) {
      instance.acquireTokenSilent(loginRequest)
        .then(response => {
          setToken(response.accessToken);
          setLoading(false);
      });
    };
  }, [inProgress, instance, isAuthenticated]);

  useEffect(() => {
    const getToken = () => {
      if (instance.getActiveAccount()) {
          instance.acquireTokenSilent(loginRequest)
            .then(response => {
              setToken(response.accessToken);
              setLoading(false);
            }).catch(err => {
                console.log("joku error",err);
            });
      } else {
          setToken("11")
          setLoading(true)
      };
    };
    getToken();
  }, [instance, loggedIn]);

  // console.log("isLoading",isLoading)
  // console.log("loggedIn",loggedIn)

  if (isLoading) {
    return <div className="App">Loading...</div>;
  }

  const searchKey = token;

  const connector = new AppSearchAPIConnector({
    searchKey,
    engineName,
    hostIdentifier,
    endpointBase
  });

  const config = {
    searchQuery: {
      facets: buildFacetConfigFromConfig(),
      ...buildSearchOptionsFromConfig()
    },
    autocompleteQuery: buildAutocompleteQueryConfig(),
    apiConnector: connector,
    alwaysSearchOnInitialLoad: true
  };

  return (
    <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
      <SearchProvider config={config}>
        <WithSearch mapContextToProps={({ wasSearched }) => ({ wasSearched })}>
          {({ wasSearched }) => {
            return (
              <div className="App">
                <ErrorBoundary>
                  <Layout
                    header={<SearchBox autocompleteSuggestions={true} />}
                    sideContent={
                      <div>
                        {wasSearched && (
                          <Sorting
                            label={"Sort by"}
                            sortOptions={buildSortOptionsFromConfig()}
                          />
                        )}
                        {getFacetFields().map((field: any) => (
                          <Facet key={field} field={field} label={field} />
                        ))}
                      </div>
                    }
                    bodyContent={
                      <Results
                        resultView={VendorResultView}
                        titleField={getConfig().titleField}
                        urlField={getConfig().urlField}
                        thumbnailField={getConfig().thumbnailField}
                        shouldTrackClickThrough={true}
                      />
                    }
                    bodyHeader={
                      <React.Fragment>
                        {wasSearched && <PagingInfo />}
                        {wasSearched && <ResultsPerPage />}
                      </React.Fragment>
                    }
                    bodyFooter={<Paging />}
                  />
                </ErrorBoundary>
              </div>
            );
          }}
        </WithSearch>
      </SearchProvider>
    </MsalAuthenticationTemplate>
  );
}
